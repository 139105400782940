@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
}

section {
  display: flex;
}

.sidebar {
  margin-top: 3.9rem;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  width: 110px;
  height: 100vh;
  padding: 0 1.7rem;
  color: #fff;
  overflow: hidden;
  transition: width 0.5s linear, transform 0.5s linear;
  background: #25aae1;
}

.sidebar:hover {
  width: 240px;
}

.logo {
  height: 80px;
  padding: 16px;
}

.menu {
  list-style: none;
  padding: 0;
}

.menu li {
  padding: 1rem;
  margin: 8px 0;
  border-radius: 8px;
  transition: background 0.5s ease-in-out;
}

.menu li:hover,
.menu li.active {
  background: #e0e0e058;
}

.menu a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.menu a span {
  opacity: 0;
  transition: opacity 0.5s;
}

.sidebar:hover .menu a span {
  opacity: 1;
}

.menu a i {
  font-size: 1.2rem;
}

.logout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* Dropdown menu */
.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  background-color: #25aae1;
  padding: 10px;
  border-radius: 8px;
}

.dropdown:hover .dropdown-menu {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: #fff;
}

.dropdown-menu li:hover,
.dropdown-menu li.active {
  background: #e0e0e058;
}

.dropdown-menu li span {
  opacity: 0;
  transition: opacity 0.5s;
}

.sidebar:hover .dropdown-menu li span {
  opacity: 1;
}

/* Main Body section */
.main--content {
  margin-top: 3.9rem;
  background: #0a0d36;
  width: 100%;
  padding: 1rem;
  transition: margin-left 0.5s linear;
}

.header--wrapper img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
}

.header--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 10px 2rem;
  margin-bottom: 1rem;
}

.header--title {
  color: #25aae1;
}

.user--info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.search--box {
  background: rgb(237, 237, 237);
  border-radius: 15px;
  color: #25aae1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 12px;
}

.search--box input {
  background: transparent;
  padding: 10px;
}

.search--box i {
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.search--box i:hover {
  transform: scale(1.2);
}

/* Card Container */
.card--container {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
}

.card--wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.main--title {
  color: #25aae1;
  padding-bottom: 10px;
  font-size: 15px;
}

.payment--card {
  background: rgb(229, 223, 223);
  border-radius: 10px;
  padding: 1.2rem;
  width: 290px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
}

.payment--card:hover {
  transform: translateY(-5px);
}

.card--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.amount {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 12px;
  font-weight: 200;
}

.amount--value {
  font-size: 24px;
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
}

.icon {
  color: #fff;
  padding: 1rem;
  height: 60px;
  width: 60px;
  text-align: center;
  border-radius: 50%;
  font-size: 1.5rem;
  background: red;
}

.card-detail {
  font-size: 18px;
  color: #777777;
  letter-spacing: 2px;
  font-family: "Courier New", Courier, monospace;
}

/* Color CSS */
.light-red {
  background: rgb(251, 233, 233);
}

.light-purple {
  background: rgb(254, 226, 254);
}

.light-green {
  background: rgb(235, 236, 235);
}

.light-blue {
  background: rgb(236, 236, 254);
}

.dark-red {
  background: red;
}

.dark-purple {
  background: purple;
}

.dark-green {
  background: green;
}

.dark-blue {
  background: blue;
}

/* Tabular Wrapper */
.tabular--wrapper {
  background: #fff;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 2rem;
}

.table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background: rgba(113, 99, 186, 255);
  color: #fff;
}

th {
  padding: 15px;
  text-align: left;
}

tbody {
  background: #f2f2f2;
}

td {
  padding: 15px;
  font-size: 14px;
  color: #333;
}

tr:nth-child(even) {
  background: #fff;
}

tfoot {
  background: rgba(113, 99, 186, 255);
  font-weight: bold;
  color: #fff;
}

tfoot td {
  padding: 15px;
  color: #fff;
}

.table-container button {
  color: green;
  background: none;
  cursor: pointer;
}

/* Cards Row */
.cards-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
}

.cards-row .flex {
  flex: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .main--content {
    width: 100%;
    margin-left: 0;
  }

  .payment--card {
    flex: 1 1 calc(100% - 1rem);
  }

  th,
  td {
    font-size: 14px;
    padding: 10px;
  }

  h1 {
    font-size: 1.25rem;
  }

  .table-container {
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .header--wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .user--info {
    width: 100%;
    justify-content: space-between;
  }

  .search--box {
    width: 100%;
    margin-top: 10px;
  }

  .header--wrapper img {
    width: 40px;
    height: 40px;
  }

  .main--content {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
  }

  .sidebar {
    width: 240px;
    transform: translateX(-100%);
    position: fixed;
    z-index: 1000;
    transition: transform 0.5s ease;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .hamburger {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    color: #c10006;
    position: fixed;
    top: 70px;
    left: 20px;
    z-index: 2000;
  }

  .close-sidebar {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .payment--card {
    width: 100%;
    margin-bottom: 1rem;
  }
}
