@import "../../mixins.scss";
.carousel {
    margin-bottom: 50px;
    .contentWrapper {
        position: relative;
    }
    .carouselTitle {
        font-size: 24px;
        color: white;
        margin-bottom: 20px;
        font-weight: normal;
    }
    .arrow {
        font-size: 30px;
        color: black;
        position: absolute;
        top: 44%;
        transform: translateY(-50%);
        cursor: pointer;
        opacity: 0.5;
        z-index: 1;
        display: none;
        @include md {
            display: block;
        }
        &:hover {
            opacity: 0.8;
        }
    }
    .carouselLeftNav {
        left: 30px;
    }
    .carouselRighttNav {
        right: 30px;
    }
    .loadingSkeleton {
        display: flex;
        gap: 10px;
        overflow-y: hidden;
        margin-right: -20px;
        margin-left: -20px;
        padding: 0 20px;
        @include md {
            gap: 20px;
            overflow: hidden;
            margin: 0;
            padding: 0;
        }
        .skeletonItem {
            width: 125px;
            @include md {
                width: calc(25% - 15px);
            }
            @include lg {
                width: calc(20% - 16px);
            }
            flex-shrink: 0;
            .posterBlock {
                border-radius: 12px;
                width: 100%;
                aspect-ratio: 1 / 1.5;
                margin-bottom: 30px;
            }
            .textBlock {
                display: flex;
                flex-direction: column;
                .title {
                    width: 100%;
                    height: 20px;
                    margin-bottom: 10px;
                }
                .date {
                    width: 75%;
                    height: 20px;
                }
            }
        }
    }
    .cardItems {
        display: flex;
        gap: 10px;
        overflow-y: hidden;
        margin-right: -20px;
        margin-left: -20px;
        padding: 0 20px;
        @include md {
            gap: 20px;
            // overflow: hidden;
            margin: 0;
            padding: 0;
        }
        .cardItem {
            width: 125px;
            cursor: pointer;
            @include md {
                // width: calc(25% - 15px);
                width: 100%;
            }
            @include lg {
                // width: calc(20% - 16px);
                width: 100%;
            }
            flex-shrink: 0;
            .posterBlock {
                position: relative;
                width: 100%;
                aspect-ratio: 1 / 1.5;
                background-size: cover;
                background-position: center;
                margin-bottom: 30px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding: 10px;
                .lazy-load-image-background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .tags {
                    display: none;
                    position: relative;
                    @include md {
                        display: flex;
                        flex-flow: wrap;
                        justify-content: flex-end;
                    }
                }
            }
            .textBlock {
                color: white;
                display: flex;
                flex-direction: column;
                .title {
                    font-size: 16px;
                    margin-bottom: 10px;
                    line-height: 24px;
                    @include ellipsis(1);
                    @include md {
                        font-size: 20px;
                    }
                }
                .date {
                    font-size: 14px;
                    opacity: 0.5;
                }
            }
        }
    }
}
