.tags {
    display: flex;
    gap: 5px;
    .tag {
        background-color: var(--pink);
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 4px;
        color: white;
        white-space: nowrap;
        text-transform: capitalize;
    }
}