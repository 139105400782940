.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 15rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.add-new-post__editor .ql-toolbar {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
